




































































































































































































































































































































































































import { Component, Prop, Ref } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import ReportQuery from '@/models/ReportQuery'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import QueryFilter from '@/components/QueryFilter/QueryFilter.vue'
import WebMessage from '../../models/WebMessage'
import User from '../../models/User'

@Component({
  components: {
    Widget,
    SelectPicker,
    DatePicker,
    IconAction,
    FormInput,
    QueryFilter,
  },
})
export default class ReportEdit extends ViewModel {
  @Ref() readonly shareForm!: HTMLFormElement

  @Ref() readonly saveForm!: HTMLFormElement

  @Prop({ default: null })
  public id!: string | null

  public loading: boolean = false

  public report: ReportQuery = new ReportQuery()

  public title: string = 'Create Report'

  public show_report: boolean = true

  public data: object[] | null = []

  public fields: object[] = []

  public sortBy: string = 'date'

  public sortDesc: boolean = true

  public busy: boolean = false

  public custom_to_options: any = []

  public to_options: any = []

  public get email_picker_options() {
    return [...this.to_options, ...this.custom_to_options]
  }

  public modal: any = {
    delete: false,
    send: false,
    filter: false,
    save: false,
  }

  public get dimension_options() {
    const options = [
      { name: 'Date', value: 'date' },
      { name: 'Hour', value: 'hour' },
      { name: 'Order', value: 'media_plan' },
      { name: 'Line Item', value: 'media_plan_item' },
      { name: 'Advertiser', value: 'advertiser' },
      { name: 'Publisher', value: 'publisher' },
      { name: 'Device', value: 'device_type' },
      { name: 'Region', value: 'region' },
    ]

    if (this.user.isSuperAdmin) {
      options.push({ name: 'Agency', value: 'agency' })
      options.push({ name: 'Order (Ad Server)', value: 'order' })
      options.push({ name: 'Line Item (Ad Server)', value: 'line_item' })
    }
    if (!this.user.isStation) {
      options.push({ name: 'Station Market', value: 'station' })
    }

    return options
  }

  public get metric_options() {
    const options = [
      { name: 'Impressions', value: 'impressions' },
      { name: 'First Quartile', value: 'first_quartile' },
      { name: 'Midpoint', value: 'midpoint' },
      { name: 'Third Quartile', value: 'third_quartile' },
      { name: 'Completed', value: 'completed' },
      { name: 'Completion Rate', value: 'completion_rate' },
    ]

    return options
  }

  public period_options: object[] = [
    { name: 'None', value: 'none' },
    { name: 'Daily (Everyday)', value: 'daily' },
    { name: 'Weekly (Every Monday)', value: 'weekly' },
    { name: 'Monthly (1st day of Month)', value: 'monthly' },
  ]

  public addTag(newTag: string) {
    if (this.validateEmail(newTag)) {
      const tag = {
        name: newTag,
        value: newTag,
      }
      this.custom_to_options.push(tag)
      this.report.to.push(newTag)
    } else {
      WebMessage.error('Please enter a valid email.')
    }
  }

  public validateEmail(email: string) {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return res.test(email.toLowerCase())
  }

  public filterModal() {
    this.modal.filter = true
  }

  public saveModal() {
    this.modal.save = true
  }

  public confirmDelete() {
    this.modal.delete = true
  }

  public handleSaveOk() {
    this.saveForm.validate().then((success: boolean) => {
      if (!success) {
        return
      }
      this.modal.save = false
      this.save()
    })
  }

  public handleShareOk() {
    this.shareForm.validate().then((success: boolean) => {
      if (!success) {
        return
      }
      this.shareConfirm()
    })
  }

  public save() {
    this.loading = true

    this.report
      .save()
      .then(response => {
        this.loading = false
        if (response.status == 200) {
          this.$router.push({ name: 'Reports' })
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  public cancel() {
    this.$router.push({ name: 'Reports' })
  }

  public download() {
    this.loading = true
    this.report.download().then(() => (this.loading = false))
  }

  public share() {
    this.modal.send = true
  }

  public shareConfirm() {
    this.modal.send = false
    this.loading = true
    this.report
      .share()
      .then(() => (this.loading = false))
      .catch(() => (this.loading = false))
  }

  public created() {
    if (this.user.report_view_id) {
      this.report.settings.filters.view = this.user.report_view_id
    }
    if (this.id) {
      this.loading = true
      ReportQuery.get(this.id).then(o => {
        if (o instanceof ReportQuery) {
          this.report = o
          this.title = 'Edit Report'

          this.populateEmailOptions()
        }
        this.loading = false
      })
    } else {
      const { query } = this.$route

      if (query.from && typeof query.from === 'string') {
        ReportQuery.get(query.from).then(o => {
          if (o instanceof ReportQuery) {
            this.report = Object.assign(new ReportQuery(), o)
            this.report.id = null
          }

          this.populateEmailOptions()
          this.loading = false
        })
      } else if (this.user.type === 'sales') {
        // @ts-ignore
        this.report.settings.filters.sales_reps.push(this.user.id)
      }
    }
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'

    User.module
      .searchOptions({
        search: search.includes('*') ? search : `*${search}*`,
      })
      .then(response => {
        this.to_options = response.map((o: any) => ({ name: o.name, value: o.detail }))
        this.cleanCustomToList()
        this.busy = false
      })
  }

  public populateEmailOptions() {
    if (this.report.to.length > 0) {
      this.report.to.forEach(item => {
        if (!this.custom_to_options.some((o: any) => o.value == item)) {
          this.custom_to_options.push({
            name: item,
            value: item,
          })
        }
      })
      this.busy = true
      User.module
        .searchOptions({
          value: this.custom_to_options,
        })
        .then(response => {
          this.to_options = response
          this.cleanCustomToList()
          this.busy = false
        })
    }
  }

  public cleanCustomToList() {
    if (this.custom_to_options.length > 0) {
      this.custom_to_options = this.custom_to_options.filter((o: any) => {
        if (this.report.to.includes(o.value)) return true
        return !this.to_options.some((so: any) => so.value.toLowerCase() == o.value.toLowerCase())
      })
    }
  }

  public getTotal(key: string) {
    let total = 0
    let count = 0

    for (const i in this.data) {
      if (key == 'completion_rate') {
        // @ts-ignore
        total += parseFloat(this.data![i][key])
      } else {
        // @ts-ignore
        total += parseInt(this.data![i][key])
      }
      count++
    }

    if (key == 'completion_rate') {
      total /= count
    }
    return total
  }

  public run() {
    this.loading = true
    this.report
      .fetch()
      .then(response => {
        this.data = null
        this.data = response.data.result

        this.fields = []

        for (const key in response.data.result[0]) {
          if (key !== 'advertiser_id') {
            this.fields.push({
              key,
              sortable: true,
              class: 'text-center',
            })
          }
        }

        this.loading = false
      })
      .catch(error => {
        this.loading = false
        if (error.response.status == 422) {
          WebMessage.error(error.response.data.errors.dimensions[0], [
            {
              text: 'Fix Dimensions',
              action: (toast: any) => {
                const dimensions: any = []
                this.report.settings.dimensions.forEach(item => {
                  if (item != 'hour' && item != 'region') {
                    dimensions.push(item)
                  }
                })

                this.report.settings.dimensions = dimensions
                WebMessage.hide(toast.id)
                this.run()
              },
            },
            {
              text: 'Fix Metrics',
              action: (toast: any) => {
                const metrics: any = []
                this.report.settings.metrics.forEach(item => {
                  if (item == 'impressions') {
                    metrics.push(item)
                  }
                })

                this.report.settings.metrics = metrics
                WebMessage.hide(toast.id)
                this.run()
              },
            },
          ])
        }
      })
  }
}
